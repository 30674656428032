<template>
    <div>
        <div v-for="(item,index) in data" :key="index" class="tr-project print-page">
            <table class="custom-table" cellspacing="0" >
                <thead class="table-header">
                    <tr>
                        <th style="text-align: left" colspan="4">
                            <div class="tr-name">{{item.Name}}</div>
                        </th>
                    </tr>
                    <tr class="tr-th">
                        <th style="text-align: left"><div class="bth">项目名称</div></th>
                        <th style="text-align: left"><div class="bth" style="margin-left:-2px ">检查结果</div></th>
                    </tr>
                </thead>
                <tbody class="print-page">
                    <tr v-for="(items,ids) in item.items" :key="ids">
                        <td style="text-align: left">{{ items.Name }}</td>
                        <td style="text-align: left">
                            <span>{{items.Value}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                default: () => []
            },
            title:{
                default:null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-weight: bold;
        font-size: 42px;
        text-align: center;
        color: black;
        padding-bottom: 15px;
        border-bottom:2px solid #333333;
    }

    .item-jbxx {
        padding-top: 80px;
    }

    .text-title2 {
        display: flex;
        font-size: 22px;
        text-align: center;
        /*letter-spacing: 16px;*/
        align-items: center;

        .text-img {
            width: 230px;

            img {
                width: 150px;
                height: 70px;
            }
        }

    }

    .item-list {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        padding: 10px 0;
        display: flex;
        color: black;
        font-weight: 500;
        font-size: 15px;

        .label {
            width: 80px;
            padding-right: 8px;
        }

        .label-value {
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid black;
        }
    }

    .tjp {
        padding-left: 10px;
    }

    .text-sx {
        font-size: 13px;
        padding: 4px 0;
    }

    .gjsm {
        padding-top: 300px;
        text-align: right;
        border-bottom: 1.5px solid black;
        margin-bottom: 2px;
    }

    .twopage {
        display: flex;
        align-items: center; /* 垂直居中 */
        min-height: 100vh; /* 设置最小高度为视窗高度，确保垂直居中效果 */
        color: black;
        padding: 0px 10px 10px;
        font-weight: 600;
        line-height: 60px;
        font-size: 20px;

        .tw-title-text {
            text-indent: 70px;
        }
    }

    .three-page {
        .tr-project {
            border-bottom: 1.5px solid black;
            padding-bottom: 20px;
        }

        .tr-title {
            display: flex;
            align-items: center;
            /*letter-spacing: 10px;*/
            font-weight: 700;
            font-size: 26px;
            color: black;
            padding-bottom: 5px;
            border-bottom: 1.5px solid black;
            .imgs{
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }
        }

        .tr-name {
            padding: 10px 0px;
            font-size: 16px;
            width: 100%;
        }

        .custom-table {
            width: 100%;

            .tr-th .bth {
                border-bottom: 1.5px solid black;
                border-top: 1.5px solid black;
                background-color: #f5f5f5;
                color: black;
                /*margin-bottom: 30px;*/
            }

            td {
                text-align: center;
                padding: 8px 0;
            }
        }
        .yisheng {
            text-align: right;
            margin-top: -5px;
        }
    }

</style>

