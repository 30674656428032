<template>
    <div class="page">
        <el-row style="height: 100%;display: flex;">
            <el-col :span="12" class="page">
                <div class="search">
                    <el-form :inline="true" :model="search" size="small" label-width="40px">
                        <el-form-item label="项目">
                            <el-select v-model="search.type" placeholder="全部" style="width:130px" clearable>
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="结果">
                            <el-input v-model="search.name" placeholder="请选择结果" clearable style="width:130px"/>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="search.type" placeholder="全部" style="width:130px" clearable>
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%"
                          height="calc(100vh - 400px)">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="ZXT" label="测量时间" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="ZXT" label="项目" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="GYXS" label="数据来源" width="" show-overflow-tooltip/>
                    <el-table-column prop="GYXS" label="测量结果" width="" show-overflow-tooltip/>
                    <el-table-column prop="GYXS" label="结果" width="" show-overflow-tooltip/>
                    <el-table-column prop="GYLB" label="状态" width="" show-overflow-tooltip/>
                    <el-table-column label="操作"  fixed="right">
                        <template slot-scope="scope">
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
            </el-col>
            <el-col :span="12">
                <el-row >
                    <div style="text-align: center;padding: 0 10px" >
                        <el-tag v-for="(item,index) in tagList" :key="index" style="margin: 5px;cursor: pointer" :type="items.id!=item.id?'info':''" @click="handelTag(item)">{{item.name}}</el-tag>
                        <h2 v-if="items.name">{{items.name}}</h2>
                        <div :style="items.name?'height: calc( 100vh - 460px );overflow: auto;':'height: calc( 100vh - 400px );overflow: auto;'" v-if="false">
                            <zbjc-line-chart style="height: 300px"></zbjc-line-chart>
                            <div class="time-select">
                                <div class="but">全部</div>
                                <div class="but">近1月</div>
                                <div class="but">近3月</div>
                                <div class="but">近6月</div>
                                <div class="but">近1年</div>
                            </div>
                            <el-alert
                                    title="通过点击指标标签可显示或隐藏指标曲线"
                                    type="info"
                                    :closable="false"
                            >
                            </el-alert>
                            <el-collapse v-model="activeName" accordion>
                                <el-collapse-item title="解释" name="1">
                                    <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                                    <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
                                </el-collapse-item>
                            </el-collapse>
                            <el-collapse v-model="activeName" accordion>
                                <el-collapse-item title="临床意义" name="1">
                                    <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                                    <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <el-empty description="暂无数据" v-else></el-empty>
                    </div>
                </el-row>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import ZbjcLineChart from "./widgets/lineChart";
    export default {
        components: {ZbjcLineChart},
        data() {
            return {
                loading: false,
                search: {status: ''},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                activeName: "1",
                tagList:[
                    {name:"血压",id:1},
                    {name:"血糖",id:2},
                    {name:"血脂",id:3},
                    {name:"血尿酸",id:4},
                    {name:"骨密度",id:5},
                    {name:"肝功能",id:6},
                    {name:"同型半胱氨酸",id:7},
                    {name:"体重指数",id:8}
                ],
                items:{}
            }
        },
        methods: {
            handelTag(items){
                this.items = items
            },
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/gyjh/list/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .time-select{
        display: flex;
        justify-content: center;
        .but{
            padding: 5px 20px;
            border: 1px solid #c0c4cc;
            border-radius: 20px;
            margin: 10px;
            cursor: pointer;
            font-size: 13px;
        }
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
