<template>
    <el-dialog title="报告" :visible.sync="visible" width="60%" :before-close="handleClose" :close-on-click-modal="false">
        <div style="height: 50vh;overflow: auto;">
            <div v-if="form.jlb && form.jlb.JCJG">
                <h3>检查结果</h3>
                <div class="content">{{ form.jlb.JCJG }}</div>
            </div>
            <div v-if="form.jlb && form.jlb.ZJJL">
                <h3>终检结论</h3>
                <div class="content">{{ form.jlb.ZJJL }}</div>
            </div>
            <div v-if="form.jlb && form.jlb.YLBJ">
                <h3>健康建议</h3>
                <div class="content">{{ form.jlb.YLBJ }}</div>
            </div>

            <div v-if="form.ybb && form.ybb.length > 0">
                <div v-for="e, i in form.ybb" :key="`ybb-${i}`">
                    <h3>{{ e.group_name }}</h3>
                    <el-table :data="e.children" stripe border>
                        <el-table-column prop="item_name" label="项目名称" width="" />
                        <el-table-column prop="value" label="结果" width="" />
                        <el-table-column prop="unit" label="单位" width="" />
                        <el-table-column prop="default" label="参考值" width="" />
                    </el-table>
                </div>
            </div>
            <div v-if="form.hyb && form.hyb.length > 0">
                <div v-for="e, i in form.hyb" :key="`hyb-${i}`">
                    <h3>{{ e.group_name }}</h3>
                    <el-table :data="e.children" stripe border>
                        <el-table-column prop="item_name" label="项目名称" width="" />
                        <el-table-column prop="value" label="结果" width="" />
                        <el-table-column prop="unit" label="单位" width="" />
                        <el-table-column prop="default" label="参考值" width="" />
                    </el-table>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    white-space: pre-line;
}
</style>