<template>
    <div>
        <el-dialog :visible.sync="visible" width="800px" class="dialog" :title="title" :before-close="handleClose"
                   :close-on-click-modal="false" top="10vh">
            <el-form ref="form" :model="form"  v-loading="loading" label-position="top" size="mini" style="height: 500px;overflow-y: auto;" >
                <report-form :formData="tab1data" :form="form" labelwidth="0"></report-form>
                <el-form-item label="影像资料">
                    <el-upload class="upload-box" drag action="" multiple :before-upload="handleBeforeUpload">
                        <el-button v-if="(Object.keys(form.file).length!=0)" style="position: absolute;top: 5px;right:5px;z-index: 99999;"
                                   size="mini" type="danger" icon="el-icon-delete" circle @click="btnRemove"></el-button>
                        <div v-if="(Object.keys(form.file).length!=0)">
                            <img src="../../../../../assets/file.png" style="width: 50px;height: 50px;padding: 10px"/>
                            <div class="el-upload__text" >{{form.file.name}}</div>
                        </div>
                        <div v-else>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="formSubmit" :loading="loading">确 认</el-button>
                <el-button type="" size="mini" @click="handleClose">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import tab1data from "./data"
    import ReportForm from "./myform";
    export default {
        name: 'form-upload',
        components: {ReportForm},
        props: {
            visible: {
                default: false
            },
            title: {
                default: ""
            },
            form: {
                //file:{},
                default: {}
            },
        },
        data() {
            return {
                loading: false,
                tab1data:tab1data.tab1form
            }
        },
        methods: {
            btnRemove(e) {
                e.stopPropagation()
                this.form.file = {};
                this.$forceUpdate()
                this.loading = false
            },
            handleBeforeUpload(file) {
                // 如果已经有文件上传了，则清空文件列表，只保留最新上传的文件
                if (this.form.file) {
                    this.form.file = {};
                }
                this.form.file = file; // 将最新上传的文件赋值给表单数据中的文件字段
                this.$forceUpdate()
                return false; // 返回 false 阻止默认上传行为
            },
            async formSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(() => false)
                if (!valid) return
                this.loading = true
                const formData = new FormData();
                for (const key in this.form) {
                    if(key !="file")
                        formData.append(key, this.form[key]);
                }
                formData.append('file', this.form.file);
                let pk = this.$route.params.id
                const resp = await this.$http.post(`/phr/report/${pk}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (resp.data.status != 'success') {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success(resp.data.msg)
                    this.$emit('update')
                }
                this.loading = false
            },
            handleClose() {
                this.$emit('close')
            },
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .el-form-item--mini .el-form-item__content, .el-form-item--mini .el-form-item__label {
        line-height: 10px;
    }
    ::v-deep .el-upload,
    ::v-deep .el-upload-dragger {
        width: 100%;
        height: 100px;
    }
    ::v-deep .el-upload-dragger .el-icon-upload {
        margin: 0px;
    }
</style>
