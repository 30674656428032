<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="150px" size="small" :rules="rules" >
            <el-form-item label="风险人群标签：" prop="audience_tag">
                <el-select v-model="form.audience_tag" placeholder="请选择" filterable>
                    <el-option label="健康人群" value="健康人群"></el-option>
                    <el-option label="风险人群" value="风险人群"></el-option>
                    <el-option label="异常人群" value="异常人群"></el-option>
                    <el-option label="慢病人群" value="慢病人群"></el-option>
                    <el-option label="大病人群" value="大病人群"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="风险等级标签：" >
                <el-cascader :options="dbList" :props="defaultProps" collapse-tags clearable
                             v-model="form.level_tag"></el-cascader>
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import {dbList} from "../../../data/utlisData";

    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {
                    level_tag:[]
                }
            },
            title: {
                default: "批量添加标签"
            },
        },
        data() {
            return {
                dbList: dbList,
                defaultProps: {
                    multiple: true,
                    children: 'tags',
                    label: 'name',
                    value: 'value',
                    emitPath: false
                },
                rules: {
                    audience_tag: [
                        {required: true, message: '请输入风险人群标签', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            // getData() {
            //     this.$http.get(`/tag?page=1&pageSize=500`)
            //         .then(resp => {
            //             this.tagList= resp.data.data
            //         }).catch(err => {
            //         this.$message.error(err.message)
            //     })
            // },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/phr/tag`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
