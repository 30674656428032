const data = {
    tab1form: [
        {
            "name": "orgName",
            "type": "input",
            "title": "体检机构",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "reportTime",
            "type": "date",
            "title": "报告日期",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false
        },
        {
            "name": "overall_concl",
            "type": "textarea",
            "title": "总捡结论",
            span: 12,
            required: true,
            placeholder: "请输入",
            value: "",
            disabled: false,
            rows:3
        },
        {
            "name": "overall_adv",
            "type": "textarea",
            "title": "总捡建议",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 12,
            disabled: false,
            rows:3
        },
        {
            "name": "",
            "type": "title",
            "title": "一般项目",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "height",
            "type": "input",
            "title": "身高",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "weight",
            "type": "input",
            "title": "体重",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "waist",
            "type": "input",
            "title": "腰围",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "hr",
            "type": "input",
            "title": "心率",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "体脂",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "bf",
            "type": "input",
            "title": "体脂",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "",
            "type": "title",
            "title": "血压",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "sbp",
            "type": "input",
            "title": "收缩压",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "dbp",
            "type": "input",
            "title": "舒张压",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "",
            "type": "title",
            "title": "血糖",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "fbg",
            "type": "input",
            "title": "空腹血糖",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "ppbg",
            "type": "input",
            "title": "餐后2h血糖",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "hba1c",
            "type": "input",
            "title": "糖化血红蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false,
        },
        {
            "name": "",
            "type": "title",
            "title": "血脂",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "chol",
            "type": "input",
            "title": "总胆固醇",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "tg",
            "type": "input",
            "title": "甘油三酯",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "hdl",
            "type": "input",
            "title": "高密度脂蛋白胆固醇",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "ldl",
            "type": "input",
            "title": "低密度脂蛋白胆固醇",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "肾功能",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "ua",
            "type": "input",
            "title": "血尿酸",
            required: true,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "urea",
            "type": "input",
            "title": "尿素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "cr",
            "type": "input",
            "title": "肌酐",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "肝功能",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "ast",
            "type": "input",
            "title": "谷草转氨酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "alt",
            "type": "input",
            "title": "谷丙转氨酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "tbil",
            "type": "input",
            "title": "总胆红素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "tp",
            "type": "input",
            "title": "总蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "alb",
            "type": "input",
            "title": "白蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "血B2微球蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "b2m",
            "type": "input",
            "title": "B2微球蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "血胱抑素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "cystatin_c",
            "type": "input",
            "title": "胱抑素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "血常规",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "wbc",
            "type": "input",
            "title": "白细胞计数",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "rbc",
            "type": "input",
            "title": "红细胞计数",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "hb",
            "type": "input",
            "title": "血红蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "plt",
            "type": "input",
            "title": "血小板",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "尿常规",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "urobil",
            "type": "input",
            "title": "胆红素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "u_glucose",
            "type": "input",
            "title": "尿糖",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "u_blood",
            "type": "input",
            "title": "隐血",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "u_protein",
            "type": "input",
            "title": "尿蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "甲状腺功能",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "tsh",
            "type": "input",
            "title": "血清促甲状腺素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "ft3",
            "type": "input",
            "title": "游离三碘甲状腺原氨酸",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "ft4",
            "type": "input",
            "title": "游离甲状腺素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "t3",
            "type": "input",
            "title": "三碘甲状原氨酸",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "t4",
            "type": "input",
            "title": "甲状腺素",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "血同型半胱氨酸",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "hcy",
            "type": "input",
            "title": "血同型半胱氨酸",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "血超敏C反应蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "hs_crp",
            "type": "input",
            "title": "超敏C反应蛋白",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "心肌酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "ck",
            "type": "input",
            "title": "磷酸肌酸激酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "ck_mb",
            "type": "input",
            "title": "磷酸肌酸激酶同工酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "ldh",
            "type": "input",
            "title": "乳酸脱氢酶",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
        {
            "name": "",
            "type": "title",
            "title": "骨密度",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 24,
        },
        {
            "name": "bmd",
            "type": "input",
            "title": "骨密度",
            required: false,
            placeholder: "请输入",
            value: "",
            span: 6,
            disabled: false
        },
    ],
}
export default data
