<template>
    <el-dialog :visible="visible" width="1000px" @close="handleClose" @open="handleOpen" class="dialog" :title="title"
        :fullscreen="true">
        <el-tabs tab-position="left" style="height: calc(100vh - 80px);">
                <el-row>
                    <el-col :span="10" style="height: calc(100vh - 80px);overflow: scroll;padding:0 10px">
                        <el-form ref="form" :model="form" size="mini" label-position="top">
                            <el-form-item label="近期的体检指标：" prop="report">
                                <el-row>
                                    <el-select v-model="form.reportType" placeholder="体检报告类型" style="width: 100%"
                                        >
                                        <el-option v-for="item in reportType" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-row>
                                <el-row style="margin-top: 10px">
                                    <el-select v-model="form.report" placeholder="选择体检报告" style="width: 100%"
                                        >
                                        <el-option v-for="item in reportList" :key="item.id" :label="item.reportTime"
                                            :value="item.id">
                                            <span style="float: left">{{ item.reportTime }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px">{{
                                                item.orgName }}</span>
                                        </el-option>
                                    </el-select>
                                </el-row>
                                <el-row style="margin-top: 10px">
                                    <el-row v-for="(item, index) in repostDataList" :key="index" style="margin: 5px 0">
                                        <el-col :span="8">
                                            <span>{{ item.label }}</span>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-input v-model="form[item.value]" />
                                        </el-col>
                                        <el-col :span="4">
                                            <div style="text-align: center">{{ item.unit }}</div>
                                        </el-col>
                                    </el-row>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="01.您的名称：" prop="name"
                                :rules="[{ required: true, message: '必填项', trigger: 'change' }]">
                                <el-input v-model="form.name" />
                            </el-form-item>
                            <el-form-item label="02.您的性别是：" prop="sex"
                                :rules="[{ required: true, message: '必填项', trigger: 'change' }]">
                                <el-radio-group v-model="form.sex" size="small" style="width: 100%">
                                    <el-row :gutter="10">
                                        <el-col :span="12">
                                            <el-radio label="1" border>男</el-radio>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-radio label="2" border>女</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="03.您的年龄是：" prop="age"
                                :rules="[{ required: true, message: '必填项', trigger: 'change' }]">
                                <el-input v-model="form.age" />
                            </el-form-item>
                            <el-form-item label="04.您的血型是：" prop="blood"
                                :rules="[{ required: true, message: '必填项', trigger: 'change' }]">
                                <el-radio-group v-model="form.blood" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in bloodList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="05.【多选】您的药物过敏史：" prop="drugIrritability">
                                <el-checkbox-group v-model="form.drugIrritability" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in drugIrritabilityList"
                                            :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="06.【多选】您是否对以下食物或接触物过敏：">
                                <el-checkbox-group v-model="form.foodsIrritability" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in foodsIrritabilityList"
                                            :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="07.【多选】您是否有如下暴露史：" prop="expose">
                                <el-checkbox-group v-model="form.expose" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in exposeList" :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="08.【多选】您是否有如下明确诊断过的疾病：" prop="heredity">
                                <el-checkbox-group v-model="form.heredity" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in heredityList" :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="09.您是否有过外伤或手术史：" prop="surgery">
                                <el-radio-group v-model="form.surgery" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in surgeryList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="10.手术名称或时间(选填)：">
                                <el-input v-model="form.surgeryName" />
                            </el-form-item>
                            <el-form-item label="11.【多选】您的父母或直系兄弟姐妹是否有以下情况：" prop="parents">
                                <el-checkbox-group v-model="form.parents" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in parentsList" :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="12.您的吸烟情况：" prop="smoke">
                                <el-radio-group v-model="form.smoke" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in smokeList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="13.您近30天的饮酒情况：(以50度白酒为例，其它酒类请酌情评价)" prop="drink">
                                <el-radio-group v-model="form.drink" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in drinkList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="14.您的运动习惯：" prop="style">
                                <el-radio-group v-model="form.style" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in styleList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="15.【多选】您的饮食习惯：" prop="foods">
                                <el-checkbox-group v-model="form.foods" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in foodsList" :key="index">
                                            <el-checkbox :label="item.value" border>{{ item.label }}</el-checkbox>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="16.您的睡眠情况：" prop="sleep">
                                <el-radio-group v-model="form.sleep" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in sleepList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="17.您是否存在精神紧张或焦虑的情况：" prop="mental">
                                <el-radio-group v-model="form.mental" size="small">
                                    <el-row :gutter="10">
                                        <el-col :span="item.span" v-for="(item, index) in mentalList" :key="index">
                                            <el-radio :label="item.value" border>{{ item.label }}</el-radio>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="14"
                        style="height: calc(100vh - 80px);overflow: scroll;padding:0 10px;border:1px solid #ccc">
                        <div v-if="report">
                            <myreport :form="report" />
                        </div>
                        <el-empty v-else description="请填写表单"></el-empty>
                    </el-col>
                </el-row>

        </el-tabs>
    </el-dialog>
</template>

<script>
import myreport from '../../../../healthAssessment/hra3/widgets/personalAssessmentReport.vue'

export default {
    components: {
        myreport
    },
    props: {
        visible: {
            default: false
        },
        row: {
            default: {
                operation: [],
                foods: [],
                parents: [],
                heredity: [],
                expose: [],
                foodsIrritability: [],
                drugIrritability: [],
            }
        },
        title: {
            default: "评估详情"
        },
    },
    data() {
        return {
            report: null,
            form: {
                operation: [],
                foods: [],
                parents: [],
                heredity: [],
                expose: [],
                foodsIrritability: [],
                drugIrritability: [],
            },
            history: [],
            reportType: [
                { "label": "体检报告", "value": "1" },
                { "label": "小屋报告", "value": "2" },
            ],
            reportList: [],
            hyList: [
                { "label": "未婚", "value": "1", "span": 12 },
                { "label": "已婚", "value": "2", "span": 12 },
                { "label": "丧偶", "value": "3", "span": 12 },
                { "label": "离婚", "value": "4", "span": 12 },
                { "label": "不详", "value": "5", "span": 12 }
            ],
            repostDataList: [
                { "span": 12, "label": "身高 (必填)", "value": "height", "unit": "cm" },
                { "span": 12, "label": "体重 (必填)", "value": "weight", "unit": "kg" },
                { "span": 12, "label": "收缩压 (高压)", "value": "sbp", "unit": "mmHg" },
                { "span": 12, "label": "舒张压 (低压)", "value": "dbp", "unit": "mmHg" },
                { "span": 12, "label": "心率", "value": "hr", "unit": "次/分" },
                { "span": 12, "label": "腰围", "value": "waist", "unit": "cm" },
                { "span": 12, "label": "总胆固醇", "value": "chol", "unit": "mmol/L" },
                { "span": 12, "label": "低密度脂蛋白", "value": "ldl", "unit": "mmol/L" },
                { "span": 12, "label": "高密度脂蛋白", "value": "hdl", "unit": "mmol/L" },
                { "span": 12, "label": "空腹血糖", "value": "fbg", "unit": "mmol/L" },
                { "span": 12, "label": "同型半胱氨酸", "value": "hcy", "unit": "umol/L" },
                { "span": 12, "label": "血尿酸", "value": "ua", "unit": "umol/L" },
                { "span": 12, "label": "甘油三酯", "value": "tg", "unit": "mmol/L" },
            ],
            cpmpanyTypeList: [
                { "label": "本部机关及直属部门", "value": "1", "span": 12 },
                { "label": "考研单位", "value": "2", "span": 12 },
                { "label": "生产单位", "value": "3", "span": 12 },
                { "label": "后勤辅助单位", "value": "4", "span": 12 }
            ],
            jobTypeList: [
                { "label": "野外施工作业", "value": "1", "span": 12 },
                { "label": "后勤生产作业", "value": "2", "span": 12 },
                { "label": "安全监督", "value": "3", "span": 12 },
                { "label": "科研人员", "value": "4", "span": 12 },
                { "label": "生产一线-操作作业", "value": "5", "span": 12 },
                { "label": "生产一线-技术管理", "value": "6", "span": 12 },
                { "label": "生产一线-机关办公", "value": "7", "span": 12 },
                { "label": "生产一线-实验检测", "value": "8", "span": 12 },
                { "label": "基地/总部/公司-操作作业", "value": "9", "span": 24 },
                { "label": "基地/总部/公司-技术管理", "value": "10", "span": 24 },
                { "label": "基地/总部/公司-机关办公", "value": "11", "span": 24 },
                { "label": "基地/总部/公司-实验检测", "value": "12", "span": 24 },
                { "label": "野外施工项目管理", "value": "13", "span": 12 },
                { "label": "其它", "value": "14", "span": 12 }
            ],
            companyJobList: [
                { "label": "操作岗", "value": "1", "span": 12 },
                { "label": "管理岗", "value": "2", "span": 12 },
                { "label": "技术岗", "value": "3", "span": 12 },
                { "label": "离退休人员", "value": "4", "span": 12 },
                { "label": "在册离岗", "value": "5", "span": 12 },
                { "label": "其它", "value": "14", "span": 12 }
            ],
            operationList: [
                { "label": "高空作业", "value": "1", "span": 12 },
                { "label": "高温作业", "value": "2", "span": 12 },
                { "label": "电工作业", "value": "3", "span": 12 },
                { "label": "压力容器作业", "value": "4", "span": 12 },
                { "label": "高寒作业", "value": "5", "span": 12 },
                { "label": "高原作业", "value": "6", "span": 12 },
                { "label": "视屏作业", "value": "7", "span": 12 },
                { "label": "有限空间作业", "value": "8", "span": 12 },
                { "label": "缺氧危险作业", "value": "9", "span": 12 },
                { "label": "高湿作业", "value": "10", "span": 12 },
                { "label": "噪音作业", "value": "11", "span": 12 },
                { "label": "粉尘作业", "value": "12", "span": 12 },
                { "label": "高紫外线作业", "value": "13", "span": 12 },
                { "label": "电离辐射作业", "value": "14", "span": 12 },
                { "label": "激光作业", "value": "15", "span": 12 },
                { "label": "无人区作业", "value": "16", "span": 12 },
                { "label": "孤独作业", "value": "17", "span": 12 },
                { "label": "驾驶作业", "value": "18", "span": 12 },
                { "label": "油气场所作业", "value": "19", "span": 12 },
                { "label": "含硫场所作业", "value": "20", "span": 12 },
                { "label": "含汞场所作业", "value": "21", "span": 12 },
                { "label": "其它有毒化学物质接触作业", "value": "22", "span": 24 },
            ],
            bloodList: [
                { "label": "A型", "value": "1", "span": 12 },
                { "label": "B型", "value": "2", "span": 12 },
                { "label": "AB型", "value": "3", "span": 12 },
                { "label": "O型", "value": "4", "span": 12 },
                { "label": "不详或其它", "value": "5", "span": 12 }
            ],
            drugIrritabilityList: [
                { "label": "青霉素", "value": "1", "span": 12 },
                { "label": "头孢类", "value": "2", "span": 12 },
                { "label": "磺胺类", "value": "3", "span": 12 },
                { "label": "链霉素", "value": "4", "span": 12 },
                { "label": "地卡因", "value": "5", "span": 12 },
                { "label": "破伤风抗毒素", "value": "6", "span": 12 },
                { "label": "其它", "value": "7", "span": 12 },
                { "label": "不详或以上全无", "value": "8", "span": 12 }
            ],
            foodsIrritabilityList: [
                { "label": "坚果", "value": "1", "span": 12 },
                { "label": "芒果", "value": "2", "span": 12 },
                { "label": "牛奶", "value": "3", "span": 12 },
                { "label": "海鲜", "value": "4", "span": 12 },
                { "label": "鸡蛋", "value": "5", "span": 12 },
                { "label": "花粉", "value": "6", "span": 12 },
                { "label": "霉菌", "value": "7", "span": 12 },
                { "label": "动物皮毛", "value": "8", "span": 12 },
                { "label": "其它", "value": "9", "span": 12 },
                { "label": "不详或以上全无", "value": "10", "span": 12 }
            ],
            exposeList: [
                { "label": "化学品", "value": "1", "span": 12 },
                { "label": "毒物", "value": "2", "span": 12 },
                { "label": "射线", "value": "3", "span": 12 },
                { "label": "不详或以上全无", "value": "4", "span": 12 }
            ],
            heredityList: [
                { "label": "高血压(有明确诊断)", "value": "1", "span": 12 },
                { "label": "冠心病(心绞痛、心肌梗塞、冠状动脉粥样硬化性心脏病等)", "value": "2", "span": 24 },
                { "label": "冠脉综合征", "value": "3", "span": 12 },
                { "label": "颈动脉斑块或狭窄", "value": "4", "span": 12 },
                { "label": "心律失常(室性心律失常、室上性心律失常、心房颤动、II度及以上房室传导阻滞)", "value": "5", "span": 24 },
                { "label": "主动脉夹层", "value": "6", "span": 12 },
                { "label": "冠脉支架或搭桥术后", "value": "7", "span": 12 },
                { "label": "心脏起搏器植入术后", "value": "8", "span": 12 },
                { "label": "糖尿病(有明确诊断)", "value": "9", "span": 12 },
                { "label": "痛风(有明确诊断)", "value": "10", "span": 12 },
                { "label": "高脂血症(有明确诊断)", "value": "11", "span": 12 },
                { "label": "脂肪肝", "value": "12", "span": 12 },
                { "label": "脑梗塞", "value": "13", "span": 12 },
                { "label": "脑出血", "value": "14", "span": 12 },
                { "label": "短暂性脑缺血发作", "value": "15", "span": 12 },
                { "label": "脑动脉瘤", "value": "16", "span": 12 },
                { "label": "高血压视网膜病变", "value": "17", "span": 12 },
                { "label": "眼底动脉狭窄或出血", "value": "18", "span": 12 },
                { "label": "哮喘", "value": "19", "span": 12 },
                { "label": "重度睡眠呼吸暂停综合征(有明确诊断)", "value": "20", "span": 24 },
                { "label": "下肢深静脉血栓", "value": "21", "span": 12 },
                { "label": "肺栓塞", "value": "22", "span": 12 },
                { "label": "慢性阻塞性肺病", "value": "23", "span": 12 },
                { "label": "急性胰腺炎", "value": "24", "span": 12 },
                { "label": "恶性肿瘤", "value": "25", "span": 12 },
                { "label": "不详或以上全无", "value": "26", "span": 12 }
            ],
            parentsList: [
                { "label": "50岁以前发生心脑血管病", "value": "50岁以前发生心脑血管病", "span": 24 },
                { "label": "高血压", "value": "高血压", "span": 12 },
                { "label": "糖尿病", "value": "糖尿病", "span": 12 },
                { "label": "冠心病", "value": "冠心病", "span": 12 },
                { "label": "脑出血", "value": "脑出血", "span": 12 },
                { "label": "脑梗赛", "value": "脑梗赛", "span": 12 },
                { "label": "慢性阻塞肺病", "value": "慢性阻塞肺病", "span": 12 },
                { "label": "恶性肿瘤", "value": "恶性肿瘤", "span": 12 },
                { "label": "不详或以上全无", "value": "", "span": 12 }
            ],
            surgeryList: [
                { "label": "无", "value": "0", "span": 12 },
                { "label": "有", "value": "1", "span": 12 }
            ],
            smokeList: [
                { "label": "本人从不吸烟或戒烟超过15年，也不被动吸二手烟", "value": "本人从不吸烟或戒烟超过15年，也不被动吸二手烟", "span": 24 },
                { "label": "本人从不吸烟，但被动吸二手烟(每天15分钟以上)", "value": "本人从不吸烟，但被动吸二手烟(每天15分钟以上)", "span": 24 },
                { "label": "现已戒烟但不足15年", "value": "现已戒烟但不足15年", "span": 24 },
                { "label": "吸烟，平均每天少于20支", "value": "吸烟，平均每天少于20支", "span": 24 },
                { "label": "吸烟，平均每天不少于20支", "value": "吸烟，平均每天不少于20支", "span": 24 }
            ],
            drinkList: [
                { "label": "不喝酒或单次饮酒小于1两", "value": "不喝酒或单次饮酒小于1两", "span": 24 },
                { "label": "单次饮酒2-4两", "value": "单次饮酒2-4两", "span": 12 },
                { "label": "单次饮酒5-8两", "value": "单次饮酒5-8两", "span": 12 },
                { "label": "单次饮酒大于8两或经常醉酒", "value": "单次饮酒大于8两或经常醉酒", "span": 24 }
            ],
            styleList: [
                { "label": "长期办公室工作，除日常生活，几乎不进行体力活动", "value": "长期办公室工作，除日常生活，几乎不进行体力活动", "span": 24 },
                { "label": "从事体力劳动，或平时有按时运动的习惯", "value": "从事体力劳动，或平时有按时运动的习惯", "span": 24 }
            ],
            foodsList: [
                { "label": "荤素均衡", "value": "荤素均衡", "span": 12 },
                { "label": "荤食为主", "value": "荤食为主", "span": 12 },
                { "label": "素食为主", "value": "素食为主", "span": 12 },
                { "label": "清淡", "value": "清淡", "span": 12 },
                { "label": "咸", "value": "咸", "span": 12 },
                { "label": "甜", "value": "甜", "span": 12 },
                { "label": "生", "value": "生", "span": 12 },
                { "label": "冷", "value": "冷", "span": 12 },
                { "label": "硬", "value": "硬", "span": 12 },
                { "label": "高油脂", "value": "高油脂", "span": 12 },
                { "label": "熏腌", "value": "熏腌", "span": 12 },
                { "label": "辛辣", "value": "辛辣", "span": 12 },
            ],
            sleepList: [
                { "label": "充足（7小时及以上）", "value": "充足（7小时及以上）", "span": 12 },
                { "label": "一般（5-6小时）", "value": "一般（5-6小时）", "span": 12 },
                { "label": "不佳（5小时以下）", "value": "不佳（5小时以下）", "span": 12 }
            ],
            mentalList: [
                { "label": "基本没有", "value": "基本没有", "span": 12 },
                { "label": "偶尔（每周1-2天）", "value": "偶尔（每周1-2天）", "span": 12 },
                { "label": "经常（每周≥3天）", "value": "经常（每周≥3天）", "span": 12 }
            ]
        }
    },
    watch: {
        // 监控 form.report 的变化
        'form.reportType': function (newValue, oldValue) {
            this.handelReport(newValue, 1)
        }
    },
    methods: {
        handelReport(v, type = null) {
            this.reportList = []
            if (type && type == 1) {
                console.log(type)
            } else {
                this.form.report = ""
            }
            this.$http.get(`/phr/report/${this.row.id}?page=1&pageSize=500&type=${v}`)
                .then(resp => {
                    this.reportList = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        handleClose() {
            this.$emit('close')
            this.form = {
                operation: [],
                foods: [],
                parents: [],
                heredity: [],
                expose: [],
                foodsIrritability: [],
                drugIrritability: [],

            }
        },
        handleOpen() {
            if(this.row.report){
                this.form = JSON.parse(JSON.stringify(this.row.report))
                this.report = this.row.report
                this.report.guardian = this.row.guardian
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

::v-deep .el-radio-group {
    width: 100%;
}

::v-deep .el-radio--small.is-bordered {
    width: 100%;
    margin: 5px 0;
    /*display: flex;*/
    /*align-items: center;*/
}

::v-deep .el-checkbox {
    display: block;
    /* 或者 flex，使得checkbox占据整行 */
    white-space: normal;
    /* 允许文本换行 */
    word-break: break-all;
    /* 在长单词或URL内部进行断行 */
}

::v-deep .el-checkbox--small.is-bordered {
    width: 100%;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

::v-deep .el-checkbox.is-bordered.el-checkbox--small {
    height: inherit;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
