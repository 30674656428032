<template>
    <div style="max-height:  calc(100vh - 420px);overflow: auto">
        <div style="margin:20px;letter-spacing: 1px;font-family: 'SimSun', '宋体', Arial, sans-serif;" id="printTest">
            <el-row class="three-page">
                <card-jl :form="form" title="总检室"/>
            </el-row>
            <el-row class="three-page" v-for="(value, key) in tableData" :key="key">
                <card-yb :data="value" :title="value[0].name"/>
            </el-row>
        </div>
    </div>
</template>

<script>
    import CardJL from './printModel/cardJL'
    import CardYB from "./printModel/cardYB";
    import data from "./data";
    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {}
            },
        },
        components: {
            'card-jl': CardJL,
            'card-yb': CardYB,
        },
        data() {
            return {
                print: {
                    id: 'printTest',
                },
                loadingForm: false,
                tableData: {},
                currentPage: 1,
                ybxmList: [{
                    'Name': '一般项目', items: [
                        {Name: '身高', Value: this.form.content.weight},
                        {Name: '体重', Value: this.form.content.height},
                        {Name: '腰围', Value: this.form.content.waist},
                        {Name: '心率', Value: this.form.content.hr},
                    ]
                }]
            }
        },
        methods: {
            handleClose() {
                this.$emit('close')
            },
            transformData() {
                const result = {};
                data.tab1form.forEach((item, index) => {
                    if (item.type === "title" && !item.name) {
                        const titlePinyin = index.toString();
                        const itemsArray = [];
                        for (let nextIndex = index + 1; nextIndex < data.tab1form.length; nextIndex++) {
                            const nextItem = data.tab1form[nextIndex];
                            if (nextItem.type === "title") break; // 遇到下一个title时停止
                            const value = this.form.content[nextItem.name];
                            itemsArray.push({
                                Name: nextItem.title,
                                Value: value,
                            });
                        }
                        if (!result[titlePinyin]) {
                            result[titlePinyin] = [{Name: item.title, items: itemsArray}];
                        } else {
                            result[titlePinyin].push({Name: item.title, items: itemsArray});
                        }
                    }
                });
                console.log(result)
                this.tableData = result;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .text-title {
        font-weight: bold;
        font-size: 42px;
        text-align: center;
        /*letter-spacing: 14px;*/
        color: black;
        padding-bottom: 15px;
        border-bottom: 1.5px solid #333333;
    }

    .item-jbxx {
        padding-top: 80px;
    }

    .text-title2 {
        display: flex;
        font-size: 22px;
        text-align: center;
        letter-spacing: 16px;
        align-items: center;

        .text-img {
            width: 230px;

            img {
                width: 150px;
                height: 70px;
            }
        }

    }

    .item-list {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
        padding: 10px 0;
        display: flex;
        color: black;
        font-weight: 500;
        font-size: 15px;

        .label {
            width: 80px;
            padding-right: 8px;
        }

        .label-value {
            height: 20px;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid black;
        }
    }

    .tjp {
        padding-left: 10px;
    }

    .text-sx {
        font-size: 13px;
        padding: 4px 0;
    }

    .gjsm {
        padding-top: 300px;
        text-align: right;
        border-bottom: 1.5px solid black;
        margin-bottom: 2px;
    }

    .twopage {
        display: flex;
        align-items: center; /* 垂直居中 */
        min-height: 100vh; /* 设置最小高度为视窗高度，确保垂直居中效果 */
        color: black;
        padding: 0px 10px 10px;
        font-weight: 600;
        line-height: 60px;
        font-size: 20px;

        .tw-title-text {
            text-indent: 70px;
        }
    }

    .three-page {
        page-break-after: always;
    }

    @media print {
        @page {
            margin-bottom: 25px; /* 在每一页的四周留出30px的边距 */
        }
        .page-footer {
            position: fixed;
            bottom: 20px;
            left: 0;
            right: 0;
            border-top: 1.5px solid black;
            text-align: center;
            z-index: 2; /* 底部内容的层叠顺序较低于页面内容 */
        }
    }

</style>
