<template>
    <div class="page">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="user-title">报告列表</div>
                <div class="search">
                    <div class="btns">
                        <el-button type="primary" size="mini" @click="handelAdd">录入报告</el-button>
                    </div>
                    <el-form :inline="true" :model="search" size="small">
                        <el-form-item label="类型">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="全部"></el-option>
                                <el-option label="体检报告" value="1"></el-option>
                                <el-option label="小屋报告" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                                  style="width: 100%;cursor: pointer;" height="calc(100vh - 420px)"
                                  :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="55"/>
                            <el-table-column label="体检机构" prop="orgName"/>
                            <el-table-column label="报告时间" prop="reportTime" :formatter="formatter.formatDateTime"/>
                            <el-table-column label="报告类型" prop="typeName"/>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="text"
                                               @click="handleScanReport(scope.row)">查看报告
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pager" v-if="table.total > table.pageSize">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                                   :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                                   @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
                </div>
            </el-col>
        </el-row>
        <el-dialog title="报告" :visible.sync="dialogTableVisible">
            <iframe :src="pdfSrc" class="pdf-preview" v-if="pdfSrc && pdfSrc.indexOf('.pdf')>=-1"></iframe>
            <el-image :src="pdfSrc" v-else></el-image>
        </el-dialog>
        <form-upload :visible="dialogVisible" :form="form" @close="dialogVisible = false"
                     @update="getData" title="录入体检指标"></form-upload>
        <reportModal :visible="reportVisible" :form="report" @close="reportVisible = false" />
    </div>
</template>

<script>
    import TijianModel from './widgets/tijianModel'
    import data from '../../data'
    import formUpload from "./widgets/formUpload";
    import reportModal from "./widgets/reportModal";
    import formatter from "../../../../data/formatter";

    export default {
        components: {
            formUpload,
            TijianModel,
            reportModal
        },
        data() {
            return {
                formatter: formatter,
                dialogTableVisible: false,
                dialogVisible: false,
                reportVisible: false,
                pdfSrc: "",
                loading: false,
                activeValue: "1",
                search: {
                    type: ""
                },
                form: {
                    file: {}
                },
                report: {},
                table: data.reportable
            }
        },
        methods: {
            handelAdd() {
                let pk = this.$route.params.id
                this.form = {
                    file: {},
                    pk: pk
                }
                this.dialogVisible = true
            },
            handleClick() {
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                this.dialogVisible = false
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/phr/report/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
            // 查看报告
            async handleScanReport(row) {
                const resp = await this.$http.get(`/phr/report/view/${row.id}`)
                this.report = resp.data.data
                this.reportVisible = true
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        .search {
            padding: 10px;
            background-color: #FAFAFA;
            border-top: 1px solid #EBEEF5;
            border-left: 1px solid #EBEEF5;
            border-right: 1px solid #EBEEF5;

            ::v-deep .el-form-item {
                padding: 0 !important;
                margin-bottom: 0 !important;
            }
        }

        .pdf-preview {
            width: 100%;
            min-height: calc(100vh - 420px);
            border: none;
        }

        .pager {
            padding-top: 10px;
            text-align: center;
        }

        .btns {
            float: right;
        }
    }

</style>
