<template>
    <div class="page">
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="评估来源">
                    <el-select v-model="search.type" placeholder="" style="width:100%" clearable >
                        <el-option label="人工评估" value="1"></el-option>
                        <el-option label="问卷评估" value="2"></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="状态">-->
<!--                    <el-select v-model="search.type" placeholder="" style="width:100%">-->
<!--                        <el-option label="全部" value="1"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading"
                          style="width: 100%;cursor: pointer" height="calc(100vh - 400px)"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                     width="55"/>
                    <el-table-column prop="created_at" label="问卷开始时间" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{scope.row.created_at|datetime}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="问卷提交时间" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{scope.row.created_at|datetime}}
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.type == '1'">人工评估</el-tag>
                            <el-tag v-if="scope.row.type == '2'">问卷评估</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="健康评估标签" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <popover-pg :report="scope.row"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="健康评估医生" width="" prop="guardian" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="text" @click="handleShowQuestionnaire(scope.row)">查看问卷
                            </el-button>
                            <!--                            <el-button type="text" size="small">查看结果</el-button>-->
                            <!--                            <el-button type="text" size="small" style="color: red">删除</el-button>-->
                            <!--                            <el-button type="text" size="small">审核报告</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogTableVisible" :row="form" @close="dialogTableVisible = false" @update="getData"
                   ref="vmodel"></data-form>
    </div>
</template>

<script>
    import data from '../../data'
    import dataForm from "./widgets/dataform";

    export default {
        name: 'HealthAssessment',
        components: {dataForm},
        data() {
            return {
                loading: false,
                dialogTableVisible: false,
                search: {type: ''},
                table: data.assessmenttable,
                form: {}
            }
        },
        methods: {
            // 查看问卷
            handleShowQuestionnaire(row) {
                this.form = row
                this.dialogTableVisible = true
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/healthAssessment/hra/pdf/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }

        .pager {
            padding-top: 10px;
            text-align: center;
        }
    }
</style>
