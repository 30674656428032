<template>
    <v-chart ref='chart' v-loading='loading' class='chart' :option='option' />
</template>

<script>
    export default {
        name: 'zbjcLineChart',
        data() {
            return {
                loading: false,
                option: {
                    legend: {
                        orient: 'horizontal',
                        show: true,
                        left: 20,
                        top: 25,
                    },
                    grid: {
                        left: '25',
                        right: '25',
                        bottom: '10',
                        top: '75',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: ['2024-06-20'],
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: true
                        },
                        axisLine: {
                            show: true
                        },
                    },
                    yAxis: {
                        type: 'value',
                        // max: max_value>=100? max_value + 100: max_value+10,
                        // max: max_value > 100 ? max_value * 2 : max_value + 10,
                        // interval: 1,
                        // nameLocation: "center",
                        axisLabel: {
                            color: '#999',
                            textStyle: {
                                fontSize: 12
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#E0E0E0'
                            }
                        },
                        axisTick: {
                            show: true
                        },
                        axisLine: {
                            show: true
                        },
                    },
                    series: [{
                        name: '上海江苏',
                        type: 'line',
                        smooth: true,
                        data: [10]
                    },
                        {
                            name: '上海浙江',
                            type: 'line',
                            smooth: true,
                            data: [20]
                        },
                        {
                            name: '上海安徽',
                            type: 'line',
                            smooth: true,
                            data: [30]
                        }
                    ]
                }
            }
        },
        methods: {
            async getData() {
                const ids = ['1000', '1001', '1002', '1003']
                const key = ['<20', '20-29', '30-39', '40-49', '50-59', '60-69', '70+']
                const request = ids.map(x => this.$http.get(`/statistics/age_pie_chart?company_id=${x}`))
                // const resp1 = this.$http.get('/statistics/age_pie_chart?company_id=1000')
                // const resp2 = this.$http.get('/statistics/age_pie_chart?company_id=1001')
                const a = await Promise.all(request)
                const aaa = a.map(e => {
                    let result = {}
                    let td = e.data.reduce((obj, item) => ((obj[item.name] = item.value), obj), {})
                    for (var i = 0; i < key.length; i++) {
                        result[key[i]] = td[key[i]] || 0
                    }
                    return result
                })
                this.option.series = []
                for (var i = 0; i < key.length; i++) {
                    console.log(aaa[0][key[i]])
                    this.option.series.push({
                        name: key[i],
                        data: [
                            aaa[0][key[i]],
                            aaa[1][key[i]],
                            aaa[2][key[i]],
                            aaa[3][key[i]]
                        ],
                        type: 'bar'
                    })
                }
                this.$refs['chart'].setOption(this.option)
            }
        },
        mounted() {
            // this.getData()
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 300px;
    }
</style>
