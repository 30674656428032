<template>
    <div class="page">
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="操作时间" >
                            <el-date-picker
                                    style="width: 250px"
                                    v-model="search.time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="动作" label-width="50px">
                            <el-select v-model="search.type" placeholder="" style="width: 150px">
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="操作人">
                            <el-select v-model="search.type" placeholder="" style="width:150px">
                                <el-option label="全部" value="1"></el-option>
                                <el-option label="无" value="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="状态" label-width="40px">
                            <el-select v-model="search.type" placeholder="" style="width:100%">
                                <el-option label="全部" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain @click="getData">查询</el-button>
                            <el-button type="" plain @click="getData">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%" height="calc(100vh - 420px)">
            <el-table-column type="index" label="序号" align="center"
                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                             width="55"/>
            <el-table-column prop="ZXT" label="操作时间" width="" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="GYXS" label="动作" width="" show-overflow-tooltip/>
            <el-table-column prop="GYLB" label="内容" width="" show-overflow-tooltip/>
            <el-table-column prop="JJCD" label="通知时间" width="" show-overflow-tooltip/>
            <el-table-column prop="GYNR" label="操作人" width=""/>
            <el-table-column prop="created_at" label="状态" align="center" show-overflow-tooltip/>
        </el-table>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                search: {status: '未开始'},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                }
            }
        },
        methods: {
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/gyjh/list/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>


    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }
    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
