<template>
    <div class="page">
        <div class="search">
            <div class="btns" style="text-align: right">
                <el-button type="primary" size="mini" @click="handelAdd">新增健康小结</el-button>
            </div>
        </div>
        <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%" height="calc(100vh - 400px)">
            <el-table-column type="index" label="序号" align="center"
                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                             width="55"/>
            <el-table-column prop="updateTime" label="编辑时间" width="" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="content" label="小结内容" width="" show-overflow-tooltip/>
            <el-table-column prop="status" label="状态" width="" show-overflow-tooltip/>
            <el-table-column prop="createBy" label="编辑人" width="" show-overflow-tooltip/>
            <el-table-column align="center" width="200" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleRowChange(scope.row)">编辑
                    </el-button>
                    <el-button type="text" size="mini" @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <el-dialog :visible.sync="visible" width="40%" title="添加/修改">
            <el-form ref="form" :model="form" label-width="100px" size="small">
                <el-form-item label="编辑时间：" prop="updateTime" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-date-picker v-model="form.updateTime" type="datetime" placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="小结内容：" prop="content" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model="form.content" type="textarea" :rows="5"/>
                </el-form-item>
                <el-form-item label="编辑人：" prop="createBy" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model="form.createBy" />
                </el-form-item>
                <el-form-item label="添加附件">
                    <el-upload class="upload-box" drag action="" multiple :before-upload="handleBeforeUpload">
                        <el-button v-if="(Object.keys(form.file).length!=0)" style="position: absolute;top: 5px;right:5px;z-index: 99999;"
                                   size="mini" type="danger" icon="el-icon-delete" circle @click="btnRemove"></el-button>
                        <div v-if="(Object.keys(form.file).length!=0)">
                            <img src="../../../../assets/file.png" style="width: 50px;height: 50px;padding: 10px"/>
                            <div class="el-upload__text" style="margin-top:-30px;">{{form.file.name}}</div>
                        </div>
                        <div v-else>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item style="padding-top: 40px;text-align: right">
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                    <el-button @click="visible=false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                visible: false,
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                form: {
                    file:[]
                },
            }
        },
        methods: {
            handelAdd(){
                this.form = {
                    file:[]
                }
                this.visible = true
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.updateTime} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/phr/healthSummaries/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handleRowChange(row){
                this.form = row
                this.visible = true
            },
            async onSubmit() {
                const valid = await this.$refs['form'].validate().then(res => res).catch(() => false)
                if (!valid) return
                let pk = this.$route.params.id
                const formData = new FormData();
                formData.append('updateTime', this.form.updateTime);
                formData.append('content', this.form.content);
                formData.append('createBy', this.form.createBy);
                formData.append('file', this.form.file);
                formData.append('id', this.form.id||"");
                const resp = await this.$http.post(`/phr/healthSummaries/${pk}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                if (resp.data.status != 'success') {
                    this.$message.error(resp.data.msg)
                    this.visible = false
                } else {
                    this.visible = false
                    this.$message.success(resp.data.msg)
                    this.getData()
                }
            },
            btnRemove(e) {
                e.stopPropagation()
                this.form.file = {};
                this.$forceUpdate()
                this.loading = false
            },
            handleBeforeUpload(file) {
                // 如果已经有文件上传了，则清空文件列表，只保留最新上传的文件
                if (this.form.file) {
                    this.form.file = {};
                }
                this.form.file = file; // 将最新上传的文件赋值给表单数据中的文件字段
                this.$forceUpdate()
                return false; // 返回 false 阻止默认上传行为
            },
            dateFormatter(v) {
                return new Date(v.project.startTime).format('yyyy-MM-dd')
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.loading = true
                let pk = this.$route.params.id
                const resp = await this.$http.get(`/phr/healthSummaries/${pk}?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>


    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }
    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 5px !important;
        }
    }


    .pager {
        padding-top: 10px;
        text-align: center;
    }
    ::v-deep .el-upload,
    ::v-deep .el-upload-dragger {
        width: 100%;
        height: 100px;
    }
    ::v-deep .el-upload-dragger .el-icon-upload {
         margin: 0px;
    }
</style>
