import { render, staticRenderFns } from "./followUp.vue?vue&type=template&id=e72e845a&scoped=true&"
import script from "./followUp.vue?vue&type=script&lang=js&"
export * from "./followUp.vue?vue&type=script&lang=js&"
import style0 from "./followUp.vue?vue&type=style&index=0&id=e72e845a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e72e845a",
  null
  
)

export default component.exports