<template>
    <div>
        <el-card class="box-card" shadow="never">
            <!--            <el-row class="title">-->
            <!--                <el-col :span="24">-->
            <!--                    <table class="custom-table" cellspacing="0" >-->
            <!--                        <thead class="table-header">-->
            <!--                        <tr class="tr-th">-->
            <!--                            <th class="header" colspan="9" >-->
            <!--                                <div class="tr-titlse">-->
            <!--                                    <img src="../../../../../../assets/title.png" class="imgs">-->
            <!--                                    大庆油田总医院体检中心.体检报告-->
            <!--                                </div>-->
            <!--                            </th>-->
            <!--                        </tr>-->
            <!--                        </thead>-->
            <!--                        <tbody class="print-page-td">-->
            <!--                        <tr>-->
            <!--                            <td style="background-color:#dfdfdf">体检号码</td>-->
            <!--                            <td style="width: 100px">{{form.ID}}</td>-->
            <!--                            <td style="background-color:#dfdfdf;width: 50px">姓名</td>-->
            <!--                            <td style="width: 70px">{{form.name}}</td>-->
            <!--                            <td style="background-color:#dfdfdf;width: 70px">性别</td>-->
            <!--                            <td style="width: 0px" colspan="2">{{form.sex}}</td>-->
            <!--                            <td style="width: 50px;;background-color:#dfdfdf">年龄</td>-->
            <!--                            <td style="width: 40px">{{form.age}}</td>-->
            <!--                        </tr>-->
            <!--                        <tr>-->
            <!--                            <td style="background-color:#dfdfdf">证件号码</td>-->
            <!--                            <td colspan="3">{{form.idCard}}</td>-->
            <!--                            <td style="background-color:#dfdfdf" colspan="2">电&nbsp;&nbsp;话</td>-->
            <!--                            <td colspan="3">{{form.mobile}}</td>-->
            <!--                        </tr>-->
            <!--                        <tr>-->
            <!--                            <td style="background-color:#dfdfdf">体检类型</td>-->
            <!--                            <td colspan="3"></td>-->
            <!--                            <td style="background-color:#dfdfdf" colspan="2">体检日期</td>-->
            <!--                            <td colspan="3">{{form.DJSJ | datetime}}</td>-->
            <!--                        </tr>-->
            <!--                        <tr>-->
            <!--                            <td style="background-color:#dfdfdf">单&nbsp;&nbsp;位</td>-->
            <!--                            <td colspan="3">{{form.BMGW}}</td>-->
            <!--                            <td style="background-color:#dfdfdf" colspan="2">部&nbsp;&nbsp;门</td>-->
            <!--                            <td colspan="3"></td>-->
            <!--                        </tr>-->
            <!--                        </tbody>-->
            <!--                    </table>-->
            <!--                </el-col>-->
            <!--            </el-row>-->
            <table class="custom-table" cellspacing="0">
                <thead class="table-header">
                <tr class="tr-th">
                    <th class="header">
                        <div class="tr-boder"></div>
                        <div class="bth">终检结论</div>
                    </th>
                </tr>
                </thead>
                <tbody class="print-page">
                <tr>
                    <td class="content">
                        <div>{{ form.content.overall_concl }}</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </el-card>
        <el-card class="box-card" shadow="never">
            <table class="custom-table" cellspacing="0">
                <thead class="table-header">
                <tr class="tr-th">
                    <th class="header">
                        <div class="tr-boder"></div>
                        <div class="bth">健康建议</div>
                    </th>
                </tr>
                </thead>
                <tbody class="print-page">
                <tr>
                    <td class="content">{{ form.content.overall_adv }}</td>
                </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                default: () => []
            },
            form: {
                default: () => {
                }
            },
            title: {
                default: null
            }
        },
        filters: {
            datetime(v) {
                if (v) {
                    let d = new Date(v)
                    return d.format('yyyy-MM-dd')
                }
                return v
            }
        }
    }
</script>

<style scoped lang="scss">
    .box-card {
        margin-bottom: 20px;
    }

    .custom-table {
        width: 100%;
    }

    .header {
        padding: 10px 0;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
    }

    .tr-boder {
        /*border: 1px dashed #e4e7ed;*/
        /*height: 30px;*/
        /*margin: 10px 0;*/
    }

    .content {
        white-space: pre-wrap;
        text-align: left;
        padding: 10px;
        border: 1px dashed #e4e7ed;
    }

    .images {
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .SHDate {
        width: 100%;
        text-align: center;
        margin-top: 20px;

        .SHDoctor {
            padding-left: 60px;
        }
    }

    ::v-deep .el-card {
        border: 1px solid #ffffff;
    }

    ::v-deep .el-card__body, .el-main {
        padding: 10px 0;
    }

    ::v-deep .el-card__header {
        padding: 10px 0;
    }

    .tr-titlse {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 26px;
        color: black;
        padding-bottom: 5px;
    }

    .tr-title {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 26px;
        color: black;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin-bottom: 50px;
    }

    .imgs {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .print-page-td td {
        text-align: center;
        padding: 5px;
        border: 1px solid #c0c4cc;
    }

    .title {
        border-bottom: 2px solid black;
        padding: 5px;
        margin-bottom: 60px;
    }
</style>
